
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    width="800px;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'150px'"
      >
        <el-form-item label="单位" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="是否包含下级单位" prop="subordinate">
          <el-radio v-model="formModel.subordinate" :label="true">是</el-radio>
          <el-radio v-model="formModel.subordinate" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
                v-model="formModel.type"
                filterable
                clearable
                placeholder="请选择"
                style="width:40%"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-time-picker
            v-model="formModel.startTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
            :picker-options="{
              selectableRange: `00:00:00 -${
                formModel.endTime ? formModel.endTime + ':00' : '23:59:00'
              }`,
            }"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-time-picker
            v-model="formModel.endTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
            :picker-options="{
              selectableRange: `${
                formModel.startTime ? formModel.startTime + ':00' : '00:00:00'
              }-23:59:00`,
            }"
          ></el-time-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import inspectionConfigApi from "@/api/base/inspectionConfig";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";

import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      typeList:[]
    };
  },
  created() {
    var self = this;
    dataDictionaryApi.findByCatalogName({
      "catalogName": "晨午检配置"
    }).then(response => {
      var jsonData = response.data;
      this.typeList = jsonData.data;
    });

    this.getCurrentMonthFirst();
    this.loadTree();
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }
            self.loading = true;

            if (id == null || id.length == 0) {
              return inspectionConfigApi.add(self.formModel);
            } else {
              return inspectionConfigApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return inspectionConfigApi.create();
      } else {
        return inspectionConfigApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if(self.formModel.subordinate == null || self.formModel.subordinate == ""){
            self.formModel.subordinate = false;
          }
          if(self.formModel.classifier == null || self.formModel.classifier == ""){
            self.formModel.classifier = 1;
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>
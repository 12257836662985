var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticStyle:{"text-align":"left"},attrs:{"visible":_vm.showDialog,"title":_vm.title,"modal-append-to-body":false,"width":"800px;","close-on-click-modal":false},on:{"update:visible":function($event){_vm.showDialog=$event},"close":_vm.closeDialog}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"user-panel"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formModel,"rules":_vm.ruleValidate,"label-width":'150px'}},[_c('el-form-item',{attrs:{"label":"单位","prop":"companyId"}},[_c('el-select-tree',{attrs:{"props":_vm.props,"options":_vm.companyResult,"height":"200"},model:{value:(_vm.formModel.companyId),callback:function ($$v) {_vm.$set(_vm.formModel, "companyId", $$v)},expression:"formModel.companyId"}})],1),_c('el-form-item',{attrs:{"label":"是否包含下级单位","prop":"subordinate"}},[_c('el-radio',{attrs:{"label":true},model:{value:(_vm.formModel.subordinate),callback:function ($$v) {_vm.$set(_vm.formModel, "subordinate", $$v)},expression:"formModel.subordinate"}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":false},model:{value:(_vm.formModel.subordinate),callback:function ($$v) {_vm.$set(_vm.formModel, "subordinate", $$v)},expression:"formModel.subordinate"}},[_vm._v("否")])],1),_c('el-form-item',{attrs:{"label":"类型","prop":"type"}},[_c('el-select',{staticStyle:{"width":"40%"},attrs:{"filterable":"","clearable":"","placeholder":"请选择"},model:{value:(_vm.formModel.type),callback:function ($$v) {_vm.$set(_vm.formModel, "type", $$v)},expression:"formModel.type"}},_vm._l((_vm.typeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"开始时间","prop":"startTime"}},[_c('el-time-picker',{staticClass:"date-box",attrs:{"format":"HH:mm","value-format":"HH:mm","picker-options":{
            selectableRange: `00:00:00 -${
              _vm.formModel.endTime ? _vm.formModel.endTime + ':00' : '23:59:00'
            }`,
          }},model:{value:(_vm.formModel.startTime),callback:function ($$v) {_vm.$set(_vm.formModel, "startTime", $$v)},expression:"formModel.startTime"}})],1),_c('el-form-item',{attrs:{"label":"结束时间","prop":"endTime"}},[_c('el-time-picker',{staticClass:"date-box",attrs:{"format":"HH:mm","value-format":"HH:mm","picker-options":{
            selectableRange: `${
              _vm.formModel.startTime ? _vm.formModel.startTime + ':00' : '00:00:00'
            }-23:59:00`,
          }},model:{value:(_vm.formModel.endTime),callback:function ($$v) {_vm.$set(_vm.formModel, "endTime", $$v)},expression:"formModel.endTime"}})],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":_vm.handleSubmit}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/inspectionConfig/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/inspectionConfig/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/inspectionConfig/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/inspectionConfig/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/inspectionConfig/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/inspectionConfig/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/inspectionConfig/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,create,edit,add,update,remove,batchRemove
}